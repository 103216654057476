<template>
	<Popup name="ecole"
	text="<h3>École à feu</h3><br/><p>Les salariés sont initiés par groupe de 10 au cours d’ateliers animés par les pompiers professionnels de la raffinerie. L’objectif : acquérir les réflexes et la conduite à tenir en cas d’incendie, professionnel ou domestique.</p><br/><div class='popup__statistics'><p class='dark__red'>Chiffre clé</p><p>300 personnes participent aux ateliers par an.</p></div>"
	video="static/video/ecole-a-feu.mp4"
	sticker="static/logo-total.jpg">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/ecole_a_feu_2_eyag1v_c_scale_w_350.jpg 350w,
		img/popup/ecole_a_feu_2_eyag1v_c_scale_w_679.jpg 679w,
		img/popup/ecole_a_feu_2_eyag1v_c_scale_w_831.jpg 831w"
		data-src="img/popup/ecole_a_feu_2_eyag1v_c_scale_w_831.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</Popup>
</template>

<script>
	export default {
		components: {
			Popup: () => import('@/components/Popup.vue'),
		},
	}
</script>
